<template>
  <div>
    <!-- 国际物流 -->

    <Head />
    <div class="main">
      <!-- 顶部图片 -->
      <div class="raku-top-pic">
        <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96f8c273b8.png" alt="" />
        <div class="top-text">
          <img src="../../assets/jingtaiye-pic/guojiwuliu/About RAKUMART.png" alt="" />
          <div class="text">
            <h3>Rakumart's Logistics and Shipping Services</h3>
            <p>The Rakumart platform can manage the entire logistics process of importing goods from China on your
              behalf.
              The use of the Rakumart's platform can revolutionize the way you source and import from China, saving your
              business time and procurement costs.</p>
          </div>
        </div>
      </div>
      <div class="Ouradvantage">
        <h3>Our advantages</h3>
        <div class="top-list">
          <div>
            <div class="lefe-pic"><img
                src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d96fd99744f.svg" alt=""></div>
            <div class="right-box">
              <h3>Door-to-door delivery </h3>
              <p>Rakumart provides you with DDP trade mode. We will provide you with the entire process of services from
                product procurement, international transportation, customs clearance, domestic delivery in the United
                States, to the goods delivered to you.</p>
            </div>
          </div>
          <div>
            <div class="lefe-pic"><img
                src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d970da9c0d5.svg" alt=""></div>
            <div class="right-box">
              <h3>Cost optimization</h3>
              <p>We can help you collect goods from different suppliers and arrange international delivery together to
                save delivery costs.</p>
            </div>
          </div>
          <div>
            <div class="lefe-pic"><img
                src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d970f43749c.svg" alt=""></div>
            <div class="right-box">
              <h3>Warehousing in China</h3>
              <p>We have our own warehouse in China and are responsible for the logistics and storage management of all
                goods.</p>
            </div>
          </div>
          <div>
            <div class="lefe-pic"><img
                src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d971090109e.svg" alt=""></div>
            <div class="right-box">
              <h3>Quality assurance</h3>
              <p>When we receive the goods, we will carry out the quality inspections.</p>
            </div>
          </div>
          <div>
            <div class="lefe-pic"><img
                src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9711f5f41c.svg" alt=""></div>
            <div class="right-box">
              <h3>Personalized service</h3>
              <p>We can accommodate any need for customized services from customers.</p>
            </div>
          </div>
          <div>
            <div class="lefe-pic"><img
                src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d97132ce8c2.svg" alt=""></div>
            <div class="right-box">
              <h3>Customer focus</h3>
              <p>We have dedicated partners in every link from China to the United States.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Logistics process -->
      <div class="Logisticsprocess">
        <h3>Logistics process</h3>
        <div class="piclist">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d970b571d98.svg" alt="">
        </div>
        <div class="text-list">
          <div>Shipment from factory </div>
          <div>Shipment in China</div>
          <div>Warehouse in China</div>
          <div>China customs</div>
          <div>International delivery</div>
          <div>United States
            US customs</div>
          <div>US domestic shipping</div>


        </div>
      </div>
      <!-- map-div -->
      <div class="map-div">
        <div class="text-box">
          <h3 class="title">Complete Supply Chain Logistics</h3>
          <h5>We are with you from purchase to delivery to your door</h5>
          <p> Rakumart provides complete logistics services from the supplier's factory to your receiving address.
          </p>
          <p style="margin-top:8px;"> This allows our customers to monitor at any time where their goods are at in the
            import process.</p>


          <p style="margin-top: 8px;">
            We have more than 70,000 square meters of warehouses in China, where we receive, process and store goods
            until
            they are shipped to the United States.</p>
        </div>


        <div class="img">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9708cdf1c7.svg" alt="">
        </div>

      </div>

      <div class="transport">
        <div class="heard"><span>Transportation</span><span>Minimum starting weight</span><span>Delivery time</span>
        </div>
        <div><span>Air</span><span>12KG</span><span>9 - 15 days</span></div>
        <div class="huise"><span>Matson ocean shipping</span><span>21KG</span><span>23 - 30 days</span></div>
        <div><span>FEDEX</span><span>0.5KG</span><span>7 - 10 days</span></div>
      </div>
      <!-- 底部区域 -->
      <div class="footer-box">
        <div class="img">
          <img src="../../assets/jingtaiye-pic/sheyingfuwu/contact.svg" />
        </div>
        <h3>Do you need a quote for the international shipments?</h3>
        <p style="margin-bottom:10px ;">Please contact an agent.</p>
        <p style="margin-bottom:10px ;">Please follow the steps to submit your order or provide us with information
          about
          the items you want to ship so
        </p>
        <p> we can assist you.</p>
        <div class="bottom" @click="chat">Contact an agent</div>
      </div>
      <!-- RAKUMART's  -->
      <div class="RAKUMART-pic-right">
        <div class="left-text">
          <h3>RAKUMART's logistics adds a competitive advantage for us, so that our customers can enjoy more convenient
            logistics at lower prices.</h3>
          <p><img src="../../assets/jingtaiye-pic/guojiwuliu/success.svg" alt=""><span>24/7 support at all stages prior
              to
              delivery</span></p>
          <p><img src="../../assets/jingtaiye-pic/guojiwuliu/success.svg" alt=""><span>24/7 support at all stages prior
              to
              delivery</span></p>
          <p><img src="../../assets/jingtaiye-pic/guojiwuliu/success.svg" alt=""><span>Includes storage, management and
              quality control of goods</span></p>
          <p><img src="../../assets/jingtaiye-pic/guojiwuliu/success.svg" alt=""><span>Track logistics status directly
              from the system control panel</span></p>
        </div>
        <div class="right-pic">
          <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d9703a39747.png" alt="">
        </div>
      </div>
      <div class="RAKUMART-pic-left">

        <div class="left-pic"> <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66d97000518b2.png"
            alt=""></div>
        <div class="right-text">
          <h3 style="margin-bottom: 20px;">Logistics Centers in China and the United States</h3>
          <p> We have logistics centers that can guarantee the overall safety of cargo loading, where our dedicated team
            performs daily tasks such as goods picking, storage, handling, quality control and more.</p>
          <p style="margin-top: 8px;">
            Rakumart has a huge advantage in this business area because we have extensive national site coverage, as
            well
            as
            extensive supply chain experience.</p>
        </div>

      </div>
      <!--Logistics services include  -->
      <div class="Logisticsservicesinclude">
        <h3>Logistics services include</h3>
        <div class="list">
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services1.svg" alt=""> <span>Receive goods from
              factory</span></div>
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services2.svg" alt=""> <span>Storage</span></div>
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services3.svg" alt=""> <span>Goods packing </span></div>
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services4.svg" alt=""> <span>Quality control</span></div>
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services5.svg" alt=""> <span>Production of product
              certificate</span></div>
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services6.svg" alt=""> <span>Customs clearance</span>
          </div>
          <div><img src="../../assets/jingtaiye-pic/guojiwuliu/services7.svg" alt=""> <span>Other services </span>
          </div>

        </div>
      </div>
      <!-- Information -->

      <div class="Information">
        <h3>Information about international shipping and international delivery</h3>
        <div class="list">



          <div>
            <h4>Estimated cost</h4>
            <p> In order to provide a shipping quote, we need to know whether the items included in your order are
              subject
              to
              import or export restrictions, whether they are in stock at the factory, their dimensions, the exact
              weight
              of
              the product, etc.

            </p>
            <p style="margin-top: 8px;"> That's why our team needs to contact suppliers to find out such information and
              to provide a cost estimate.</p>
            <p style="margin-top: 8px;">You will receive detailed estimates of shipping costs, cubic volumes and
              delivery
              times for the three available
              shipping methods (Ocean, Air, FedEx).</p>
          </div>
          <div>
            <h4>Final quotation and shipping</h4>


            <p> The best shipping quotes are only available after the goods arrive at our warehouse. Therefore, accurate
              shipping quotes and other charges are calculated after we receive the goods.

            </p>
            <p style="margin-top: 8px;"> Once the item arrives at our warehouse, we will group the goods in order to
              provide the best price.</p>

          </div>

          <div>
            <h4>Freight breakdown</h4>
            <p style="margin-bottom:8px ;"> International shipping fees

            </p>
            <p style="margin-bottom: 12px;">International shipping fees to the United States.</p>
            <p style="margin-bottom: 8px;">
              US domestic logistics fees</p>
            <p>After customs clearance in the United States, we will arrange domestic delivery according to the delivery
              address.
            </p>
          </div>

          <div>
            <h4>Dangerous goods transportation</h4>
            <p style="margin-bottom:12px ;">Hazardous goods are objects with the following characteristics and
              attributes:
              threatening human life and health; causing irreparable damage to the environment; causing damage to
              physical
              objects.
            </p>

            <p> Such goods include:animals and animal products, plant products, animal and vegatable fats and oils, food
              agent, ordinary
              metals, precision instruments, weapons, ammunition and artwork.
            </p>
          </div>
          <div>
            <h4>Choosing of international shipping company</h4>
            <p style="margin-bottom:10px ;"> Rakumart will choose reliable, fast and economical international logistics
              channels for your goods.
            </p>

            <p style="margin-bottom:10px ;"> After careful evaluation, we comprehensively consider factors such as
              shipping costs, shipping timeliness
              and
              service stability.
            </p>
            <p style="margin-bottom:10px ;"> We will continuously work on to find the best logistics partners and
              shipping
              routes to serve you on our
              platform.</p>
          </div>



          <div>
            <h4>Customs clearance</h4>
            <p style="margin-bottom:10px ;"> The customs clearance will be determined according to the customs clearance
              method (sea, air or land) you choose
              when you request delivery.
            </p>

            <p style="margin-bottom:10px ;"> Our company is responsible for handling relevant customs clearance matters.
            </p>
            <p> Our company will take care of related customs clearance matters. Customers can also choose to go through
              customs
              clearance procedures by themselves.</p>


          </div>


        </div>
      </div>
      <!-- 底部区域 -->
      <div class="footer-box footbox">
        <div class="img">
          <img src="../../assets/jingtaiye-pic/sheyingfuwu/contact.svg" />
        </div>
        <h3>Contact agent</h3>
        <p style="margin-bottom:10px ;"> Are you looking for the best solution for your Chinese imports?</p>
        <p style="margin-bottom:10px ;"> The answer is: Rakumart. We are professional agents who will take care of
          everything for you from purchasing the
        </p>
        <p> goods to delivering them to your doorstep. Contact us and let our advantages work for you.</p>
        <div class="bottom" @click="chat">Contact agent</div>



      </div>
      <Dialog ref="dialog" :config="config" />
    </div>
    <Foot />


  </div>
</template>
<script>
import Foot from "../../components/foot/Foot.vue";
import Head from "../../components/head/HomePageTop.vue";
import Dialog from '../../components/public/Dialog.vue'
export default {
  components: {
    Foot,
    Head,
    Dialog
  },
  data() {
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
    };
  },
  methods: {
    chat(e) {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi("是否前往登录?"),
      //     () => {
      //       this.$store.commit("getactivePage", this.$route.fullPath);
      //       this.$fun.toPage("/login");
      //     },
      //     () => { }
      //   );
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  margin: 0 auto;
  margin-top: -25px;
  padding-bottom: 81px;
  overflow: hidden;

  .raku-top-pic {
    width: 100%;
    height: 456px;
    position: relative;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .top-text {
      width: 660px;
      height: 274px;
      position: absolute;
      left: 50%;
      transform: translateX(-106%);
      top: 144px;

      img {
        width: 191px;
        height: 18px;
        margin-bottom: 33px;
      }

      .text {
        h3 {
          font-size: 21px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #FFFFFF;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
        }
      }
    }
  }

  .Ouradvantage {
    width: 1400px;
    margin: auto;
    margin-top: 91px;
    padding-top: 29px;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
    }

    .top-list {
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;


      >div {
        width: 440px;
        height: 196px;
        border-radius: 10px;
        margin-bottom: 40px;
        // background-color: pink;
        padding: 20px;
        display: flex;

        .left-pic {
          width: 40px;
          height: 40px;
          background: #698CFF;
          border-radius: 6px;

        }

        .right-box {
          margin-left: 20px;
          flex: 1;

          h3 {
            text-align: left;
            font-size: 18px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
            margin-bottom: 10px;
          }

          p {
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
            line-height: 24px
          }

        }


      }


    }

  }

  .Logisticsprocess {
    margin: auto;
    width: 1400px;

    // background-color: pink;
    margin-top: 10px;
    padding: 0px 40px;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin-bottom: 60px;
    }

    .text-list {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 120px;

      div {
        width: 130px;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        line-height: 24px;
        text-align: center;
      }

    }
  }

  .map-div {
    margin: auto;
    width: 100%;
    margin-top: 120px;
    padding-top: 30px;
    height: 560px;
    background: #001869;

    position: relative;

    .text-box {
      position: absolute;
      left: 50%;
      transform: translateX(-106%);
      top: 173px;

      h3 {
        width: 650px;

        font-size: 21px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30px;
        margin-bottom: 20px;
      }

      h5 {
        font-size: 18px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      P {
        width: 680px;
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
      }
    }

    .img {
      width: 860px;
      height: 500px;

      margin-left: 800px;
      position: absolute;
      right: 50%;
      transform: translateX(82%);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .transport {
    margin: auto;
    width: 1400px;
    height: 400px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E2E2E2;
    padding: 40px;
    margin-bottom: 120px;
    margin-top: 120px;

    >div {
      width: 1320px;
      height: 80px;

      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #666666;

        &:first-child {
          width: 200px;
        }

        &:last-child {
          width: 200px;
        }
      }
    }

    .heard {
      background: #EFF3FF;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #666666;
    }

    .huise {
      background: #F6F6F6;
    }


  }



  .footer-box {
    width: 1920px;
    height: 458px;
    background: #F6F6F6;
    background: #f6f6f6;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 81px;
    padding-top: 92px;

    .img {
      margin: 0 auto;
      width: 54px;
      height: 54px;

      margin-bottom: 40px;
    }

    h3 {
      width: 100%;
      text-align: center;
      font-size: 21px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin-bottom: 10px;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
    }

    .bottom {
      width: 240px;
      height: 60px;

      border-radius: 4px;
      border: 2px solid #ff730b;
      line-height: 60px;
      text-align: center;
      margin: auto;
      font-size: 16px;
      margin-top: 30px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ff730b;
    }
  }

  .RAKUMART-pic-right {
    width: 1400px;
    height: 400px;
    border-radius: 10px;
    margin: auto;
    margin-top: 120px;
    padding: 0 40px;
    display: flex;

    .left-text {
      flex: 1;
      padding-top: 62px;

      h3 {
        width: 650px;
        height: 90px;
        font-size: 21px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        line-height: 30px;
        margin-bottom: 34px;
      }

      P {
        display: flex;
        align-items: center;
        margin-bottom: 22px;

        img {
          margin-right: 11px;
        }

        span {
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
        }
      }
    }

    .right-pic {
      width: 640px;
      height: 400px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .RAKUMART-pic-left {
    width: 1400px;
    height: 400px;
    margin: auto;
    border-radius: 10px;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 0 40px;
    display: flex;

    .right-text {
      flex: 1;
      padding-top: 111px;
      margin-left: 40px;

      h3 {
        font-size: 21px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
      }

      p {
        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
    }

    .left-pic {
      width: 640px;
      height: 400px;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  .Logisticsservicesinclude {
    width: 1400px;
    margin: auto;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin-bottom: 60px;
    }

    .list {

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      >div {
        width: 440px;
        height: 80px;
        background: #F6F6F6;
        border-radius: 6px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        img {
          margin-right: 20px;
        }

        span {
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #222222;
        }
      }
    }
  }

  .Information {
    margin: auto;
    width: 1400px;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin-bottom: 60px;


    }

    .list {
      width: 1400px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      >div {
        width: 640px;
        height: 270px;
        border-bottom: 1px solid #E2E2E2;
        margin-bottom: 40px;

        h4 {
          font-size: 18px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #698CFF;
          margin-bottom: 20px;
        }

        p {
          width: 640px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
          line-height: 24px;

        }
      }
    }

  }

  .footbox {

    width: 1400px;
    height: 454px;
    background: #F6F6F6;
    border-radius: 10px;
    margin: auto;
    margin-top: 100px;
    padding-top: 80px;

    .img {
      margin-bottom: 30px;
    }
  }


}
</style>
